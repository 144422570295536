import './../../assets/styles/OthersBooks.css';
import bgOtherbooks from './../../assets/images/book/yellow_background.png';
import { CHUNKS_LENGTH } from './consts'
import { chunkArray } from '../../utils/common'

import React, { useMemo, useState } from "react";

const OthersBooks = (props) => {
  const [lastIndexShown, setLastIndexShown] = useState(0)
  
  const pages = useMemo(() => {
    const items = [...props.payload.listData].reverse()

    return chunkArray(items, CHUNKS_LENGTH)
  }, [props.payload.listData])

  const handleClick = e => {
    props.onSlideChange(e.target.id);
    setTimeout(() => {
      const mainTitleContainer = document.getElementById('main_title_container');
      mainTitleContainer &&
        mainTitleContainer.scrollIntoView({
          behavior: 'smooth'
        });
    }, 500);
  }

  return (
		<div className="others_books_container_overflow">
			<p className='also_read'>Leia também:</p>

			<div className="others_books_content">
        {pages.slice(0, lastIndexShown + 1).map((list, index) => (
          <React.Fragment key={index}>
            {list.map(item => (
              <div className="others_books_slide_wrapper" key={item.bookId}>
                <div
                  id={item.bookId}
                  onClick={handleClick}
                  className="others_books_slide"
                  style={{ backgroundImage: `url(${item.bookCover})` }}
                />
                <div className="text-white">
                  <p id="availability">Baixe até {item.bookAvailability}</p>
                </div>
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>

			{lastIndexShown < pages.length - 1 ? (
				<button
					className="load_more"
					onClick={() => {
						setLastIndexShown(prev => prev + 1)
					}}
				>
					VER MAIS
				</button>
			) : null}
		</div>
  ) 
}

export default OthersBooks